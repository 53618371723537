<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
    {
      expanded:
        !isVerticalMenuCollapsed ||
        (isVerticalMenuCollapsed && isMouseHovered),
    },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
  ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <!-- <b-img
                  :src="appLogoImage"
                  alt="logo"
                /> -->
              </span>
              <h2 class="brand-text">
                <!-- {{ appName }} -->
                shy-chumphon
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <!-- <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li> -->
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="(evt) => {
      shallShadowBottom = evt.srcElement.scrollTop > 0;
    }
    ">
      <!-- {{ navMenuItems }} -->
      <!-- {{ data_list }} -->

      <vertical-nav-menu-items :items="data_list" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
  data() {
    return {
      data_list: [
        { header: "Apps & Pages" },
        // {
        //   title: "จัดการออเดอร์",
        //   icon: "FileTextIcon",
        //   children: [
        //     { title: "ออเดอร์", route: "apps-invoice-list", pageOrder: "Y" },
        //     {
        //       title: "จัดรถ",
        //       route: "apps-car-arrangement-list",
        //       pageTransfer: "Y",
        //     },
        //     { title: "การเงิน", route: "apps-payment-list-new", pagePayment: "Y" },
        //   ],
        // },
        {
          title: "จัดการลูกค้า",
          icon: "FileTextIcon",
          children: [
            {
              title: 'จัดการข้อมูลพนักงาน',
              route: 'apps-employee-list',
            },
            {
              title: 'จัดการข้อมูลรถ',
              route: 'apps-carlicense-list',
              pageCar: "Y",

            },
            {
              title: 'จัดการข้อมูลคนขับ',
              route: 'apps-driver-list',
              pageCustomer: "Y",
            },
            {
              title: 'จัดการข้อมูลสาย',
              route: 'apps-route-list',
              pageRoute: "Y",
            },
          ],
        },
      ],
      items: "N",
      items_route: [],
    };
  },
  methods: {
    // async getRoute() {
    //   try {
    //     const response = await axios.post(
    //       `http://202.129.206.107:11009/listRoute`
    //     );

    //     // console.log("response", response);

    //     //    response.data;

    //     for (let i = 0; i < response.data.length; i++) {
    //       this.options_route.push(response.data[i].route);
    //     }

    //     // return todoItems;
    //   } catch (errors) {
    //     console.error(errors);
    //   }
    // },
    async removeByAttr(arr, attr, value) {
      var i = arr.length;
      while (i--) {
        if (
          arr[i] &&
          arr[i].hasOwnProperty(attr) &&
          arguments.length > 2 &&
          arr[i][attr] === value
        ) {
          arr.splice(i, 1);
        }
      }
      return arr;
    },
  },
  mounted() {


    //vue2 fucntion add
    // const items = localStorage.getItem("userData");

    const items = JSON.parse(localStorage.getItem("userData"));
    if (
      items.pageOrder == "N" &&
      items.pageTransfer == "N" &&
      items.pagePayment == "N" &&
      items.pageCustomer == "N" &&
      items.pageRoute == "N" &&
      items.pageCar == "N"
    ) {
      this.data_list = [
        {
          header: "Apps & Pages",
        },
      ];
      this.$router.push({ name: "apps-page" });
    } else {
      // console.log(`items`, items.id);
      // console.log(`items`, items);
      // console.log(`check mounted`, this.data_list.length);
      for (let i = 0; i < this.data_list.length; i++) {
        console.log("this.data_list.children", this.data_list[i].children);
        if (
          this.data_list[i].children == `` ||
          this.data_list[i].children == undefined
        ) {
          console.log("check mounted if", i);
        } else {
          console.log("check mounted else", i);
          // var routes_new = ``;
          for (let j = 0; j < this.data_list[i].children.length; j++) {
            // console.log("this.data_list[i].children", this.data_list[i].children);

            if (items.pageOrder == "N") {
              console.log("j yes");
              this.removeByAttr(this.data_list[i].children, "pageOrder", "Y");
            } else if (items.pageOrder == "Y") {
              if (this.data_list[i].children[j] == undefined) {
              } else {
                if (this.data_list[i].children[j].route == `apps-invoice-list`) {
                  this.items_route.push(this.data_list[i].children[j].route);
                }
              }
            } else {
              console.log("j no");
            }

            if (items.pageTransfer == "N") {
              console.log("j yes");
              this.removeByAttr(
                this.data_list[i].children,
                "pageTransfer",
                "Y"
              );
            } else if (items.pageTransfer == "Y") {
              if (this.data_list[i].children[j] == undefined) {
              } else {
                if (this.data_list[i].children[j].route == `apps-car-arrangement-list`) {
                  this.items_route.push(this.data_list[i].children[j].route);
                }
              }
            } else {
              console.log("j no");
            }

            if (items.pagePayment == "N") {
              console.log("j yes");
              this.removeByAttr(this.data_list[i].children, "pagePayment", "Y");
            }
            else if (items.pagePayment == "Y") {
              if (this.data_list[i].children[j] == undefined) {
              } else {
                if (this.data_list[i].children[j].route == `apps-payment-list`) {
                  this.items_route.push(this.data_list[i].children[j].route);
                }
              }
            } else {
              console.log("j no");
            }

            if (items.pageCustomer == "N") {
              console.log("j yes");
              this.removeByAttr(
                this.data_list[i].children,
                "pageCustomer",
                "Y"
              );
            } else if (items.pageCustomer == "Y") {
              if (this.data_list[i].children[j] == undefined) {
              } else {
                if (this.data_list[i].children[j].route == `apps-customer-list`) {
                  this.items_route.push(this.data_list[i].children[j].route);
                }
              }
            } else {
              console.log("j no");
            }
            if (items.pageRoute == "N") {
              console.log("j yes");
              this.removeByAttr(this.data_list[i].children, "pageRoute", "Y");
            }
            else if (items.pageRoute == "Y") {
              if (this.data_list[i].children[j] == undefined) {
              } else {
                if (this.data_list[i].children[j].route == `apps-route-list`) {
                  this.items_route.push(this.data_list[i].children[j].route);
                }
              }
            } else {
              console.log("j no");
            }

            // console.log(this.data_list[i].children[j].title ,"this.data_list[i].children[j].title ")
            if (items.pageCar == "N") {
              this.removeByAttr(this.data_list[i].children, "pageCar", "Y");
            } else if (items.pageCar == "Y") {
              if (this.data_list[i].children[j] == undefined) {
              } else {
                if (this.data_list[i].children[j].route == `apps-carlicense-list`) {
                  this.items_route.push(this.data_list[i].children[j].route);
                }
              }
            } else {
              //vue2 array add children.route to
              console.log("j no");
            }
          }

          console.log(" this.items_route", this.items_route[0]);
          this.$router.push({ name: this.items_route[0] });
        }
      }

      // this.options.push(response.data[i].shopName);
    }
  },
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>

export default [
//   {
    // title: 'Dashboards',
    // icon: 'HomeIcon',
    // tag: '2',
    // tagVariant: 'light-warning',
    // children: [
    //   {
    //     title: 'manage',
    //     route: 'dashboard-ecommerce',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //   },
//     ],
//   },
]
